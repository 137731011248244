import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function create(id, data) {
  return storeRequest.post(`products/${id}/variants`, {
    variant: data,
  });
}
function update(id, data) {
  return storeRequest.put('/variants/' + id, {
    variant: data,
  });
}
function getById(id) {
  return storeRequest.get('/variants/' + id);
}
function get(params) {
  return storeRequest.get('/variants', {
    params: { ...params },
  });
}
function remove(id) {
  return storeRequest.delete('/variants/' + id);
}
function count(params) {
  return storeRequest.get('/variants/count', {
    params: { ...params },
  });
}
function updateInventory(params) {
  return storeRequest.patch('/variants/inventory', params);
}
function updatePolicy(params) {
  return storeRequest.patch('/variants/policy', params);
}
export const variantApi = {
  create,
  get,
  getById,
  update,
  remove,
  count,
  updateInventory,
  updatePolicy,
};
