import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/payments/';

function list() {
  return storeRequest.get(path + 'all');
}

function get(id) {
  return storeRequest.get(path + id);
}

function activate(id, payment) {
  return storeRequest.post(path + 'paypal/' + id + '/activate', payment);
}

function deactivate(id, payment) {
  return storeRequest.post(path + 'paypal/' + id + '/deactivate', payment);
}

function editPayment(id, payment) {
  return storeRequest.post(path + 'paypal/' + id + '/edit', payment);
}

function create(payment) {
  return storeRequest.post(path + 'paypal', payment);
}

function update(menu) {
  return storeRequest.put(path + menu.id, menu);
}

function deleteById(id) {
  return storeRequest.delete(path + 'paypal/' + id);
}

function getRotation() {
  return storeRequest.get(path + 'rotation');
}

function createRotation(data) {
  return storeRequest.post(path + 'rotation', data);
}

function editRotation(data) {
  return storeRequest.post(path + `rotation/${data._id}/edit`, data);
}

function activeRotation(data) {
  return storeRequest.post(path + 'rotation/activate', data);
}

export const paymentService = {
  list,
  create,
  update,
  get,
  deleteById,
  editPayment,
  activate,
  deactivate,
  getRotation,
  createRotation,
  editRotation,
  activeRotation,
};
