<template>
  <div style="position: relative;">
    <div class="d-flex align-center justify-center pl-1">
      Base Cost
      <v-icon @click="toogle = !toogle">
        mdi-menu-down
      </v-icon>
    </div>
    <div class="apply-base-cost-container" v-click-outside="resetData" v-if="toogle">
      <div class="d-flex align-center">
        <input type="radio" id="INCREMENT" class="mr-1" name="baseCost" value="INCREMENT" v-model="form.action" />
        <label for="INCREMENT">Increment (+)</label><br />
      </div>
      <div class="d-flex align-center">
        <input type="radio" id="DECREMENT" class="mr-1" name="baseCost" value="DECREMENT" v-model="form.action" />
        <label for="DECREMENT">Decrement (-)</label><br />
      </div>
      <div class="d-flex align-center">
        <input type="radio" id="SETNEW" class="mr-1" name="baseCost" value="SETNEW" v-model="form.action" />
        <label for="SETNEW">Set new base cost</label><br />
      </div>
      <div class="d-flex mt-1">
        <v-text-field
          v-model.number="form.baseCost"
          :suffix="form.action === 'SETNEW' ? '$' : '%'"
          style="width: 110px;"
          type="number"
          min="0"
        />
        <v-btn color="primary" class="mt-1 ml-2" @click="onApply" depressed>Apply</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toogle: false,
      form: {
        baseCost: 0,
        action: 'INCREMENT',
      },
    };
  },
  methods: {
    resetData() {
      this.form.action = 'INCREMENT';
      this.form.baseCost = 0;
      this.toogle = false;
    },
    onApply() {
      this.$emit('applyAll', this.form);
      this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-base-cost-container {
  padding-top: 22px;
  padding-left: 22px;
  padding-right: 22px;
  position: absolute;
  z-index: 10;
  right: 110%;
  top: -200%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
::v-deep .v-text-field__suffix {
  padding-right: 12px !important;
}
</style>
