<template>
  <div class="elevation-form form-component pa-3">
    <label class="d-block mb-3"> Reviews </label>
    <p class="mb-1">Total reviews: {{ totalProductReviews }}</p>
    <p class="mb-2">Average rating: {{ averageProductReviewRate }}</p>
    <router-link
      :to="{ name: 'website.apps.product-review.manage', query: { product_id: productId } }"
      class="d-inline-block mb-4"
      >View product reviews</router-link
    >
    <v-btn depressed height="40" class="rounded-lg" block @click="modalProductReview = true">Import reviews</v-btn>
    <v-dialog persistent v-model="modalProductReview" :width="520">
      <v-card v-if="modalProductReview" class="pa-5">
        <v-btn icon style="position: absolute; right: 6px; top: 6px" @click="modalProductReview = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="mb-4">Import reviews from a spreadsheet</h2>
        <p>
          Download the
          <a href="https://minio.lattehub.com/lattehub/review_templates/template.csv" target="_blank"
            >Latte Import File Template.</a
          >
          <br />
          Fill in the reviews data you’d like to import and verify that your file meets all the requirements stated in
          the template. <br />
          Reviews that do not comply with the instructions will not be imported.
        </p>
        <v-file-input
          ref="fileUploader"
          accept=".csv"
          type="file"
          placeholder="File csv"
          hide-details
          class="import-review-input"
          v-model="reviewFile"
        />
        <div class="d-flex justify-end mt-4">
          <v-btn class="mr-3" depressed @click="modalProductReview = false">Cancel</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" :disabled="!reviewFile" @click="onImportReview"
            >Import</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    productId: String,
  },
  beforeMount() {
    this.$store.dispatch('getProductReviews', { product_id: this.productId, type: 'product' });
  },
  data() {
    return {
      modalProductReview: false,
      formData: null,
      reviewFile: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['productReviews', 'totalProductReviews', 'averageProductReviewRate']),
  },
  methods: {
    async onImportReview() {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', this.reviewFile);
        await this.$store.dispatch('importProductReview', {
          file: formData,
          productId: this.productId,
        });
      } catch (error) {
        console.log('🚀 ~ file: ReviewCard.vue:76 ~ onImportReview ~ error:', error);
        this.$store.commit('setMessages', {
          type: 'error',
          messages: 'Csv file is invalid',
        });
      } finally {
        this.isLoading = false;
        this.modalProductReview = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.import-review-input {
  border: solid 1px #dadce0;
  border-radius: 10px;
  overflow: hidden;
  ::v-deep .v-input__prepend-outer {
    margin-top: 6px;
    margin-right: 0px;
    margin-left: 2px;
  }
  ::v-deep .v-input__slot {
    border: none !important;
    background: transparent !important;
  }
  ::v-deep .v-file-input__text {
    padding-left: 12px;
  }
  ::v-deep .v-input__append-inner {
    padding-right: 6px;
    padding-top: 2px;
  }
}
</style>
