<template>
  <div class="variant-page--edit form-component">
    <!-- <v-col cols="12" class="d-flex align-center">
      <v-checkbox></v-checkbox>
      Group your variants by this option:
      <v-select :items="[]" placeholder="Option" dense></v-select>
   ádas </v-col> -->
    <!-- Là phần variant khi click vào sp -->
    <div class="d-flex justify-space-between mb-2">
      <h3>Variants ({{ $parent.listVariants.length }} / 250)</h3>
      <div>
        <!-- <a class="link-add" @click="showModalEditOption = true"> Edit options</a> -->
        <!-- @click="gotoAddVariant" là tới component AddVariant -->
        <a class="link-add mr-3" @click="reOrderOptionProduct"> Reorder variants</a>
        <a class="link-add" @click="gotoAddVariant"> Add variant</a>
      </div>
    </div>
    <!-- <pre>
        {{ $parent.listVariants }}
    </pre> -->
    <table>
      <tr>
        <th></th>
        <th v-for="i in $parent.options" :key="i._id">{{ i.name }}</th>
        <th>Inventory</th>
        <th><ApplyBaseCost @applyAll="applyAllBaseCost" /></th>
        <th>Price</th>
        <th>SKU</th>
        <th>Action</th>
      </tr>

      <tr v-for="(v, k) in $parent.listVariants" :key="v._id">
        <td class="text-center">
          <v-avatar tile size="30" height="auto" @click="addImageVariant(v, k)">
            <img
              v-if="v.image && $parent.imageKeyList[v.image]"
              :src="imageHelpers.url($parent.imageKeyList[v.image].src)"
              alt=""
            />
            <img class="pointer" v-else src="@/assets/images/no-image.png" alt="" />
          </v-avatar>
        </td>
        <td class="text-center" v-for="(o, k) in $parent.options" :key="o._id">{{ v[`option${k + 1}`] }}</td>
        <td class="text-center">{{ v.inventoryQuantity }}</td>
        <td class="text-center">{{ v.baseCost }}</td>
        <td class="text-center">{{ v.price }}</td>
        <td class="text-center"><span v-text="v.sku" class="fix-text-sku"></span></td>
        <td class="text-center">
          <i @click="gotoUpdateVariant(v)" class="fas fa-edit pointer mr-2"></i>
          <i @click="deleteteVariant(v)" class="fas fa-trash-alt pointer"></i>
        </td>
      </tr>
    </table>

    <modal :showFooter="false" model="showModalSelectionImage" :title="`Select variant image`" :callback="submitSelect">
      <template slot="content">
        <vue-select-image :h="'100px'" :w="'100px'" :dataImages="$parent.imagesSelect" @onselectimage="onSelectImage">
        </vue-select-image>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalSelectionImage = false"> Close </v-btn>
          <!-- <UploadMultiple
            :showImage="false"
            :columns="{ lg: 4, md: 4, sm: 6, xs: 12 }"
            :images="[]"
            @callback="getImageUpLoad"
          /> -->
          <v-btn @click="save" :disabled="disabledSave" color="primary"> Save </v-btn>
        </v-card-actions>
      </template>
    </modal>
    <modal width="700" model="showModalEditOption" :title="`Edit options`" :callback="submitEditOption">
      <template slot="content">
        <!-- ádsa {{ $parent.options }} -->
        <v-row v-for="(option, index) in $parent.options" :key="index" gutters class="d-flex align-center">
          <v-col cols="3"
            ><v-text-field
              lagre
              v-model="$parent.options[index].name"
              hide-details=""
              aria-autocomplete="off"
            ></v-text-field
          ></v-col>
          <v-col cols="7">
            <v-chip
              class="pa-1 ma-2"
              :color="color[`${index}`]"
              label
              close
              large
              v-for="value in $parent.options[index].values"
              :key="value"
            >
              {{ value }}
            </v-chip>
          </v-col>
          <i v-if="options.length > 1" @click="removeOption(index)" class="pointer far fa-trash-alt"></i>
        </v-row>
      </template>
    </modal>
    <modal
      width="700"
      model="showModalReorderOption"
      :title="`Reorder variants and option`"
      :callback="submitReorderOption"
      :showFooter="false"
    >
      <template slot="content">
        <div class="fluid container">
          <div class="col-md-12">
            <div class="col-12" style="padding-left: 24px">
              Reorder variants or options to change how they appear on your store.
            </div>
            <draggable
              class="list-group"
              element="ul"
              v-model="options"
              :options="dragOptions"
              :move="onMove"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <transition-group type="transition" :name="'flip-list'">
                <v-col cols="12" v-for="(element, k) in options" :key="element.name" class="d-flex align-center">
                  <div class="pointer" style="width: 100px">
                    <i class="fas fa-grip-horizontal"></i>
                    {{ element.name }}
                  </div>

                  <draggable
                    v-if="element.values"
                    class="list-group ml-5"
                    element="ul"
                    :options="dragOptions"
                    :move="onMove"
                    :list="element.values"
                  >
                    <transition-group class="list-group d-flex" :name="'flip-list'">
                      <span
                        :class="`list-group-item d-flex ml-2 custom-reoder custom-reoder-${k}`"
                        v-for="elementDeep in element.values"
                        :key="elementDeep"
                      >
                        <div class="pointer">
                          <i class="fas fa-grip-horizontal" style="color: rgb(64 45 45)"></i>
                          {{ elementDeep }}
                        </div>
                      </span></transition-group
                    >
                  </draggable>
                </v-col>
              </transition-group>
            </draggable>
          </div>
        </div>
        <v-divider class=""></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalReorderOption = false"> Close </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="submitReorderOption">
            {{ 'Save' }}
          </v-btn>
        </v-card-actions>
      </template>
    </modal>
    <delete-action :content="'variant ' + variantSelect.title" :title="'Delete ' + variantSelect.title" />
  </div>
</template>

<script>
import VueSelectImage from 'vue-select-image';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import { variantApi } from '@/apis/variants';
import draggable from 'vuedraggable';
import { productApi } from '@/apis/product';
import ApplyBaseCost from './ApplyBaseCost.vue';

export default {
  components: { VueSelectImage, draggable, ApplyBaseCost },
  data() {
    return {
      color: {
        1: '#f4ffec',
        2: '#179ee4',
        3: '',
      },
      isLoading: false,
      showModalEditOption: false,
      showModalReorderOption: false,
      disabledSave: true,
      showModalSelectionImage: false,
      imageHelpers,
      dataImages: [],
      indexItemEdit: null,
      imageSelect: null,
      imageFake,
      variantSelect: { title: '' },
      showDialogDelete: false,
      options: [],
      itemVariant: null,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      listVariantOptionDefault: [],
    };
  },
  mounted() {
    this.options = this.$parent.options;
  },
  computed: {
    dragOptions() {
      return {
        animation: 1,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost',
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  methods: {
    addClass(k) {
      console.log(this.$parent.attributes, 'this.attributes');
      // if()
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      if (typeof relatedContext.element === 'object' && typeof draggedContext.element === 'object') return true;
      if (typeof relatedContext.element === 'string' && typeof draggedContext.element === 'string') {
        if (relatedContext.list.includes(draggedContext.element)) {
          return true;
        }
      }
      return false;
      // return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    reOrderOptionProduct() {
      this.showModalReorderOption = true;
    },
    async getListVariant() {
      // Variant data
      this.isLoading = true;

      let variantIds = [];

      let getOptionValues = index => {
        // No option, no name or no values
        if (
          typeof this.options[index] === 'undefined' ||
          !this.options[index].name ||
          !this.options[index].values ||
          !this.options[index].values.length
        ) {
          return [];
        }

        return this.options[index].values;
      };
      this.listVariantOptionDefault = [];
      // Option values groupped
      let optionValues = [getOptionValues(0), getOptionValues(1), getOptionValues(2)];
      let optionValuesLength = optionValues.filter(item => item.length > 0).length;
      // Variant data generator
      let variantGenrate = [];
      let variantInit = {};
      let coppyVariant = [...this.$parent.listVariants];
      let imageVariants = {};
      let skuVariants = {};
      let priceVariants = {};
      let compareAtPriceVariants = {};
      for (let v of coppyVariant) {
        imageVariants[[v.option1, v.option2, v.option3]] = v.image;
        skuVariants[[v.option1, v.option2, v.option3]] = v.sku;
        priceVariants[[v.option1, v.option2, v.option3]] = v.price;
        compareAtPriceVariants[[v.option1, v.option2, v.option3]] = v.compareAtPrice;
      }
      console.log('imageVariants', imageVariants);
      this.listVariantOptionDefault = coppyVariant.map(item => {
        let array = [];

        if (item.option1) {
          array.push(item.option1);
        }
        if (item.option2) {
          array.push(item.option2);
        }
        if (item.option3) {
          array.push(item.option3);
        }
        return {
          options: array,
        };
      });
      // console.log('listVariantOptionDefault', listVariantOptionDefault);
      let generate = () => {
        for (let i = 0; i < optionValues[0].length; i++) {
          if (optionValuesLength === 1) {
            if (this.checkConditionOption(optionValues[0][i])) {
              variantGenrate.push({
                option1: `${optionValues[0][i]}`,
                title: `${optionValues[0][i]}`,
              });
            }
          }
          if (optionValuesLength === 2) {
            for (let j = 0; j < optionValues[1].length; j++) {
              if (this.checkConditionOption(optionValues[0][i], optionValues[1][j]))
                variantGenrate.push({
                  option1: `${optionValues[0][i]}`,
                  option2: `${optionValues[1][j]}`,
                  title: `${optionValues[0][i]}/${optionValues[1][j]}`,
                });
            }
          }
          if (optionValuesLength === 3) {
            for (let j = 0; j < optionValues[1].length; j++) {
              for (let k = 0; k < optionValues[2].length; k++) {
                if (this.checkConditionOption(optionValues[0][i], optionValues[1][j], optionValues[2][k]))
                  variantGenrate.push({
                    option1: `${optionValues[0][i]}`,
                    option2: `${optionValues[1][j]}`,
                    option3: `${optionValues[2][k]}`,
                    title: `${optionValues[0][i]}/${optionValues[1][j]}/${optionValues[2][k]}`,
                  });
                console.log(variantGenrate, 'variantGenrate');
              }
            }
          }
        }
      };
      generate(0);
      let newVariantsUpdate = [];

      this.$parent.listVariants = [];
      // let result =
      //   arrOne.length === arrTwo.length &&
      //   arrOne.every(function(element) {
      //     return arrTwo.includes(element);
      //   });
      // let skuVariants = {};
      // let priceVariants = {};
      // let compareAtPriceVariants = {};
      for (let v of variantGenrate) {
        for (let key in imageVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.image = imageVariants[key];
            break;
          }
        }
        for (let key in skuVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.sku = skuVariants[key];
            break;
          }
        }
        for (let key in priceVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.price = priceVariants[key];
            break;
          }
        }
        for (let key in compareAtPriceVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.compareAtPrice = compareAtPriceVariants[key];
            break;
          }
        }
      }
      for (let i = 0; i < coppyVariant.length; i++) {
        let obj = {
          ...coppyVariant[i],
          ...variantGenrate[i],

          imageSrc: null,
        };
        obj.inventoryPolicy =
          coppyVariant[i].inventoryPolicy &&
          coppyVariant[i].inventoryPolicy !== '' &&
          typeof coppyVariant[i].inventoryPolicy !== 'boolean'
            ? coppyVariant[i].inventoryPolicy
            : 'continue';
        console.log('obj', obj.inventoryPolicy);
        if (!obj.image || obj.image === undefined) obj.image = null;
        this.$parent.listVariants.push(obj);
      }
      // console.log(' this.$parent.listVariants', this.$parent.listVariants);
      // console.log('imageVariants', imageVariants);
      this.$parent.options = this.options;
      try {
        await this.$parent.onSubmit();
        await this.$parent.getData();
        this.showModalReorderOption = false;
      } catch (error) {}
      this.isLoading = false;
      // Tooltip
    },
    checkConditionOption(option1, option2, option3) {
      if (option1 && !option2 && !option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (this.listVariantOptionDefault[i].options.includes(option1)) {
            return true;
          }
        }
      }
      if (option1 && option2 && !option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (
            this.listVariantOptionDefault[i].options.includes(option1) &&
            this.listVariantOptionDefault[i].options.includes(option2)
          ) {
            return true;
          }
        }
      }
      if (option1 && option2 && option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (
            this.listVariantOptionDefault[i].options.includes(option1) &&
            this.listVariantOptionDefault[i].options.includes(option2) &&
            this.listVariantOptionDefault[i].options.includes(option3)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    submitReorderOption() {
      this.getListVariant();
    },
    submitEditOption() {},
    async submitDelete() {
      try {
        let res = await variantApi.remove(this.variantSelect._id);

        this.$parent.getData();
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      }
    },
    deleteteVariant(item) {
      this.variantSelect = item;
      this.showDialogDelete = true;
    },
    gotoUpdateVariant(item) {
      this.$router.push({
        name: 'website.products.variants.update',
        params: {
          id: this.$route.params.id,
          vId: item._id,
        },
      });
    },
    gotoAddVariant() {
      this.$router.push({ name: 'website.products.variants.add', params: { id: this.$route.params.id } });
    },
    addImageVariant(v, k) {
      this.showModalSelectionImage = true;
      this.indexItemEdit = k;
      this.itemVariant = v;
    },
    onSelectImage(image) {
      this.disabledSave = false;
      this.imageSelect = image;
    },
    async getImageUpLoad(images) {
      //   this.$parent.imagesSelect.push({
      //     src: this.imageHelpers.url(images.src),
      //   });
      this.$parent.attributes.images.push({
        src: images.src,
      });
      await this.$parent.onSubmit();
    },
    submitSelect() {},
    applyAllBaseCost({ baseCost, action }) {
      this.$parent?.listVariants.length &&
        this.$parent.listVariants.forEach(variant => {
          let value;
          switch (action) {
            case 'INCREMENT':
              if (baseCost <= 0) break;
              value = (baseCost * variant.baseCost) / 100;
              variant.baseCost = Number((variant.baseCost + value).toFixed(2));
              value = 0;
              break;
            case 'DECREMENT':
              if (baseCost <= 0) break;
              value = (baseCost * variant.baseCost) / 100;
              variant.baseCost = Number((variant.baseCost - value).toFixed(2));
              value = 0;
              break;
            case 'SETNEW':
              variant.baseCost = baseCost;
              break;
          }
        });
      this.$parent.attributes?.variants.length &&
        this.$parent.attributes.variants.forEach(variant => {
          let value = 0;
          switch (action) {
            case 'INCREMENT':
              if (baseCost <= 0) break;
              value = (baseCost * variant.baseCost) / 100;
              variant.baseCost = Number((variant.baseCost + value).toFixed(2));
              value = 0;
              break;
            case 'DECREMENT':
              if (baseCost <= 0) break;
              value = (baseCost * variant.baseCost) / 100;
              variant.baseCost = Number((variant.baseCost - value).toFixed(2));
              value = 0;
              break;
            case 'SETNEW':
              variant.baseCost = Number(baseCost.toFixed(2));
              break;
          }
        });
      this.$forceUpdate();
    },
    async save() {
      try {
        console.log(' this.itemVariant', this.itemVariant);
        await variantApi.update(this.itemVariant._id, {
          ...this.itemVariant,
          inventoryPolicy:
            this.itemVariant.inventoryPolicy && this.itemVariant.inventoryPolicy !== ''
              ? this.itemVariant.inventoryPolicy
              : 'continue',
          product: this.$route.params.id,
          image: {
            _id: this.imageSelect._id,
            src: this.imageSelect.srcRelative,
          },
        });
      } catch (error) {}
      // this.$parent.listVariants[this.indexItemEdit].image = this.imageSelect;
      await this.$parent.getData();
      this.showModalSelectionImage = false;
    },
  },
};
</script>

<style lang="scss">
.variant-page--edit {
  table,
  th,
  td {
    padding: 15px 5px;
  }

  table {
    width: 100%;
    border-collapse: collapse !important;
    th,
    td {
      border-left: solid 1px rgba(0, 0, 0, 0.12);
      border-right: solid 1px rgba(0, 0, 0, 0.12);
    }
    tr {
      border: solid 1px rgba(0, 0, 0, 0.12) !important;
    }
  }
}
.custom-reoder {
  padding: 5px;
}
.custom-reoder-0 {
  color: #0063b6;
  background-color: #ebf7fd;
}
.custom-reoder-1 {
  color: #288200;
  background-color: #f4ffec;
}
.custom-reoder-2 {
  color: #fff;
  background-color: #00d1b2;
}
</style>
<style scoped>
.fix-text-sku {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
